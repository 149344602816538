.main-button {
  background: none;
  border: 1px solid #680162;
  border-radius: 5px;
  color: #680162;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 2vh;
  letter-spacing: 1px;
  padding: 6px 16px;
}

.main-button:hover {
  background: #680162;
  color: #fff;
  transition: 0.2s ease-in-out;
}
