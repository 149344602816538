.site-footer {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #333333;
  display: flex;
  height: 11vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  bottom: 0;
}
