.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.whatsapp-button {
  align-items: center;
  background: #25d366;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  left: 5vw;
  padding: 1.5vh 30px;
  position: fixed;
  text-decoration: none;
  top: 85vh;
  z-index: 8;
}

.whatsapp-button:hover {
  background-color: #128c7e;
  transition: 0.2s ease;
}

.whatsapp-button:active {
  background-color: #075e54;
}

.whatsapp-logo {
  width: 4vh;
}

.whatsapp-text {
  color: #fff;
  font-weight: bold;
  font-size: 2vh;
  margin-left: 10px;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
