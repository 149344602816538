.about-wrapper {
  align-items: center;
  background-image: url("../../../img/about-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 90vh;
  justify-content: flex-end;
  padding: 0 5vw 0 0;
}

.about-background {
  width: 50vw;
  transform: scaleX(-1);
}

.about-content {
  text-align: start;
  width: 40%;
}

@media screen and (max-width: 1000px) {
  .about-wrapper {
    height: auto;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 4vh 0 4vw 0;
    background-position: bottom left;
    background-size: 200vw;
    padding-bottom: 65vh;
  }

  .about-background {
    border: 1px solid #333333;
    width: 90vw;
    height: auto;
  }

  .about-content {
    width: 90vw;
    margin-bottom: 4vh;
  }
}
