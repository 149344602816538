.production-wrapper {
  align-items: center;
  box-sizing: border-box;
  background-image: url("../../../img/production-bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  margin: 0;
  padding: 5vh 0 5vh 5vw;
}

.production-content {
  text-align: start;
  width: 50%;
}

.production-images {
  display: none;
}

.production-image {
  height: auto;
  width: 33.33%;
}

.production-background-wrapper {
  align-items: flex-end;
  display: flex;
  height: 100%;
}

.production-background {
  width: 45vw;
}

@media screen and (max-width: 1000px) {
  .production-wrapper {
    height: auto;
    padding: 4vh 0;
    flex-direction: column;
    background-color: #fff;
    background-size: 200vw;
    background-position: bottom right;
    padding-bottom: 60vh;
  }

  .production-content {
    width: 90vw;
  }

  .production-images {
    flex-direction: column;
  }

  .production-image {
    width: 100%;
  }

  .production-background {
    display: none;
  }
}
