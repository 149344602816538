.header-button {
  background: none;
  color: #333333;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vh;
  letter-spacing: 1px;
  margin-right: 2vw;
  text-decoration: none;
}

.header-button:hover {
  color: #d4571c;
  text-decoration: underline;
  transition: 0.2s ease-in-out;
}
