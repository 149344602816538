.contact-wrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: space-around;
  padding: 0 5vw 3vh 5vw;
}

.contact-form {
  text-align: start;
  width: 40%;
}

.formfields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formfield {
  background: none;
  border-bottom: 1px solid #112683;
  color: #222222;
  font-family: "Lato", sans-serif;
  font-size: 2vh;
  outline: none;
  resize: none;
  margin-bottom: 3vh;
  padding: 10px;
}

.formfield-message {
  height: 10vh;
}

.adress-data {
  width: 25vw;
}

.contact-map {
  border: 1px solid #333333;
  height: 25vw;
  margin-bottom: 5vh;
  width: 25vw;
}

.contact-image {
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .contact-wrapper {
    flex-direction: column;
    height: auto;
    padding: 4vh 0;
  }

  .contact-form {
    width: 90%;
  }

  .adress-data {
    margin-top: 4vh;
    width: 90vw;
  }

  .contact-map {
    height: 90vw;
    width: 90vw;
  }

  .contact-image {
    display: none;
  }
}
