.products-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  padding: 0 5vw;
}

.products-content {
  text-align: start;
  width: 40%;
}

.products-gallery {
  background: #fff;
  box-shadow: 0 0 8px 1px #333333;
  width: 45%;
}

.gallery-selected {
  width: 99%;
}

.gallery-images {
  width: 100%;
}

.gallery-small {
  cursor: pointer;
  width: 16%;
  margin: 0.3%;
}

.gallery-small:hover {
  opacity: 60%;
  transition: 0.2s ease;
}

@media screen and (max-width: 1000px) {
  .products-wrapper {
    flex-direction: column;
    padding: 4vh 0;
    height: auto;
  }

  .products-content {
    width: 90%;
  }

  .products-gallery {
    margin-top: 4vh;
    width: 90vw;
  }
}
