.module-wrapper {
  background: url("../../../img/gradient.jpg");
  background-size: 100vw;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 1000px) {
  .module-wrapper {
    background-size: 200vh;
  }
}
