.tabmenu-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  padding: 0 0 5vh 5vw;
}

.tabmenu-content {
  height: 70vh;
  width: 75%;
}

.tab-content-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin-bottom: 5vh;
  text-align: start;
}

.tab-button {
  background: none;
  border: 1px solid #112683;
  box-sizing: border-box;
  color: #112683;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 2vh;
  letter-spacing: 1px;
  padding: 1vh 0;
  width: 50%;
}

.active-tab-button {
  background: #112683;
  border: 1px solid #112683;
  box-sizing: border-box;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 2vh;
  letter-spacing: 1px;
  padding: 1vh 0;
  width: 50%;
}

.tab-button:hover {
  background: #7c94ff;
  border: 1px solid #7c94ff;
  color: #fff;
  transition: 0.1s ease-in;
}

.tabmenu-image-wrapper {
  align-items: center;
  border: 1px solid #333333;
  display: flex;
  height: 60vh;
  overflow: hidden;
  width: 15%;
}

.tabmenu-image {
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .tabmenu-wrapper {
    height: auto;
    flex-direction: column;
    padding: 4vh 0;
  }

  .tabmenu-content {
    height: auto;
    width: 90%;
  }

  .tab-content-wrapper {
    margin: 4vh 0;
  }

  .tabmenu-image-wrapper {
    height: 40vh;
    width: 90%;
  }
}
