.gradient-divider {
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    #112683 0%,
    #6c0164 25%,
    #b1021e 50%,
    #d4571c 75%,
    #fff 100%
  );
  border-image-slice: 1;
  width: 100%;
}
