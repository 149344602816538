.site-header {
  align-items: center;
  border-bottom: 1px solid #333333;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  height: 10vh;
  justify-content: space-between;
  padding: 0 5vw;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.header-left-content {
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}

.header-logo {
  height: 6vh;
  margin-right: 4vh;
}

.header-navbar {
  max-width: 50%;
}

.language-button {
  background: none;
  border: 1px solid #333333;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  margin-right: 2vh;
  padding: 5px 8px;
}

.language-button:hover {
  background: #6c0164;
  border: 1px solid #6c0164;
  color: #fff;
  transition: 0.2s ease;
}

.language-button-active {
  background: #6c0164;
  border: 1px solid #6c0164;
  color: #fff;
  cursor: default;
  font-family: "Rubik", sans-serif;
  margin-right: 2vh;
  padding: 5px 10px;
}

@media screen and (max-width: 1000px) {
  .site-header {
    position: initial;
  }

  .header-logo {
    height: 4.5vh;
  }

  .header-navbar {
    display: none;
  }
}
