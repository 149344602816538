.mobile-home-background {
  display: none;
}

.home-wrapper {
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("../../../img/home-bg.png");
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position-y: center;
  height: 90vh;
  margin-top: 10vh;
  padding: 0 5vw;
}

.home-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: start;
  width: 30%;
}

.main-heading {
  font-family: "Lato", sans-serif;
  font-size: 3.4vh;
  background: linear-gradient(to right, #112683, #6c0164, #b1021e, #d4571c);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text {
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 2.6vh;
}

.home-nav {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

@media screen and (max-width: 1000px) {
  .home-wrapper {
    background: none;
    background-image: none;
    margin-top: 0;
    padding: 0 0;
  }

  .mobile-home-background {
    background-image: url("../../../img/home-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    height: 30vh;
  }

  .home-content {
    background-color: #fff;
    box-sizing: border-box;
    height: auto;
    padding: 4vh 5vw;
    width: 100%;
  }
}
